<template>
	<el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectGroup">
                <el-button type="success" style="width:144px;">
                    {{curGroupLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in serverGroups" :key="item.value" :command="item" icon="el-icon-coin">
                        {{item.label}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-select v-model="curNType" style="width:144px; margin-left: 8px;" @change="onSelectNtype">
                <el-option v-for="item in ntypes" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
			<el-button type="primary" icon="el-icon-plus" @click="onClickAdd" style="margin-left: 8px;">添加新公告</el-button>
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="paramList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" class="serverlist">
				<el-table-column prop="id" label="id" width="60"> </el-table-column>
                <el-table-column label="类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ntypeName(scope.row.ntype)}}
                    </template>
                </el-table-column>
				<el-table-column prop="begin_time" label="开始时间" show-overflow-tooltip></el-table-column>
				<el-table-column prop="end_time" label="结束时间" show-overflow-tooltip></el-table-column>                    
				<el-table-column prop="title" label="公告标题" show-overflow-tooltip></el-table-column>
				<el-table-column prop="content" label="公告内容" show-overflow-tooltip></el-table-column>
				<el-table-column prop="maintain_time" label="维护时间(有则显示)" show-overflow-tooltip></el-table-column>                 
				<el-table-column prop="show_update_time" label="更新时间" show-overflow-tooltip></el-table-column>
				<el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
				<el-table-column prop="sortv" label="排序值" show-overflow-tooltip></el-table-column>                
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditNotice(scope.row)" type="primary" icon="el-icon-edit" size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickDelNotice(scope.row)" type="danger" icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
		<!-- ========弹框BEGIN====== -->
		<el-dialog :title="formTitle" :visible.sync="dialogForm" width="800px" :close-on-click-modal="false" center class="formparams">
			<el-form :model="form"  size="mini">
                <el-form-item label="【公告类型】" :label-width="formLabelWidth">
                    <el-select v-model="form.ntype" @change="onFormEdited" style="width: 100%;">
                        <el-option v-for="item in selectNtypes" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>                    
                <el-form-item label="【显示范围】" :label-width="formLabelWidth">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="列表" label-width="60px">
                                <el-select v-model="form.groupid" placeholder="选择分组" value-key="value" @change="onFormSelectGroup">
                                    <el-option v-for="item in selectGroups" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="区服" label-width="60px">
                                <el-select v-model="form.serverids" :multiple="true" placeholder="指定区服(可选)" value-key="value" @change="onFormSelectServer">
                                    <el-option v-for="item in formServers" :key="item.id" :label="item.name+'-'+item.title" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【时间范围】" :label-width="formLabelWidth">
                    <el-date-picker
                      v-model="form.notice_time"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      @blur="onFormEdited"
                      style="width: 100%;">
                    </el-date-picker>      
                </el-form-item>      
                <el-form-item label="【公告标题】" :label-width="formLabelWidth">
                    <el-input v-model="form.title" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item>  
                <el-form-item label="【公告内容】" :label-width="formLabelWidth">
                    <el-input v-model="form.content" autocomplete="off" type="textarea" :rows="3" @change="onFormEdited"></el-input>          
                </el-form-item>
                <el-form-item label="显示图片" :label-width="formLabelWidth">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="类型" label-width="100px">
                                <el-select v-model="form.imgtype" placeholder="选择类型" style="width:100%;" @change="onFormSelectImg">
                                    <el-option v-for="item in imgtypes" :key="item.name" :label="item.label" :value="item.name">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="值" label-width="60px">
                                <el-input v-model="form.imgvalue" style="width: 160px;"></el-input>
                                <el-button type="primary" size="mini" icon="el-icon-search" @click="onClickUpload" :disabled="form.imgtype!='remote'" style="margin-left: 4px;">上传</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【显示维护时间】" :label-width="formLabelWidth">
                    <el-date-picker
                      v-model="form.maintain_time"
                      type="datetime"
                      placeholder="选择日期时间"
                      @change="onFormEdited"
                      style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="【显示更新时间】" :label-width="formLabelWidth">
                    <el-date-picker
                      v-model="form.show_update_time"
                      type="datetime"
                      placeholder="选择日期时间"
                      @change="onFormEdited"
                      style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="【图片ID】" :label-width="formLabelWidth">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="标题" label-width="60px">
                                <el-input v-model="form.img_title" placeholder="标题图片ID" @change="onFormEdited"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="内容" label-width="60px">
                                <el-input v-model="form.img_id" placeholder="内容图片ID" @change="onFormEdited"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="【全局覆盖显示】" :label-width="formLabelWidth">
                    <el-switch v-model="form.cover" active-text="是" inactive-text="否"  @change="onFormEdited"></el-switch>
                </el-form-item>
                <el-form-item label="【排序值】" :label-width="formLabelWidth">
                    <el-input v-model="form.sortv" placeholder="越小越靠前" @change="onFormEdited"></el-input>
                </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onAddNotice" :disabled="noneChanged">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
		<!-- ========上传弹框BEGIN====== -->
		<el-dialog title="上传文件" :visible.sync="dialogUpload" width="480px" :close-on-click-modal="false" center class="dlgupload">
			<el-form>
				<el-form-item>
					<el-upload class="upload-demo" drag 
                    ref="upload"
                    :action="uploadAction"
                    :limit="1" 
					:file-list="uploadFileList"
                    :on-success="onUploadSuccess"
                    :on-remove="onUploadRemove"
                    :on-error="onUploadRemove">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">上传图片文件，且不超过1MB</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onCommitUploadImg" :disabled="noneUploaded">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogUpload = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========上传弹框END====== -->  
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
    import tool from '../../tool.js'

	export default {
		props: ['pkgtype'],
		data() {
			return {
				paramList: [],
                formTitle: '',
				dialogForm: false,
                form: {
                    id: '',
                    groupid:'',
                    serverids: [],
                    ntype:1,
                    title: '',
                    maintain_time: '',
                    show_update_time: '',
                    notice_time:[],
                    img_title: '',
                    img_id: '',
                    cover: false,
                    sortv: '',
                    content: '',
                    imgtype: '',
                    imgvalue: '',
                },
                formServers: [],
				noneChanged: true,
                formLabelWidth: '124px',
                serverGroups:[],
                curGroupId: -1, // 当前选择分组(默认全部)
                curGroupLabel: "全部区服列表", // 当前选择分组名,
                curNType: -1,
                ntypes: [{id:-1,name:'全部类型'},{id:1,name:'系统公告'},{id:2,name:'游戏公告'},{id:3,name:'活动公告'},{id:4,name:'维护公告'},{id:5,name:'跑马灯公告'}],
                ///////////////////////////////
                imgtypes:[{name:'none',label:'无'},{name:'local',label:'本地图片'},{name:'remote',label:'上传图片'}],
                uploadAction: this.$store.state.game.interface + '/sygame_upload_img',
                dialogUpload: false,
				noneUploaded: true, // 标记未上传(按钮状态)
				uploadFileName: '',
				uploadFileList: [],
                ///////////////////////////////
                tableHeight: document.documentElement.clientHeight-136,			
            }
		},
		created: function() {
			this.refreshNoticeList()
            this.refreshGroupList()
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
            // 新增或编辑区服的分组时，使用可选择的项目
            selectGroups() {
                return this.serverGroups.slice(1)
            },
            // 可选公告类型
            selectNtypes() {
                return this.ntypes.slice(1)
            },            
		},
		methods: {
            // 公告显示类型
            ntypeName (ntype) {
                for (let i=0; i<this.ntypes.length; i++) {
                    if (ntype == this.ntypes[i].id) {
                        return this.ntypes[i].name;
                    }
                }
                return '未知';
            },
			// 刷新列表
			refreshNoticeList() {
				this.gameHttp(3001, {groupid:this.curGroupId, ntype:this.curNType}).then((response) => {
					this.paramList = response.data.list
				})
			},
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {plain: 1}).then((response) => {
                    let list = response.data.list
                    this.serverGroups = [{value: -1,label: "全部区服列表"}]
                    for (let i=0; i<list.length; i++) {
                        this.serverGroups.push( {value:list[i].id, label:list[i].name} )
                    }
                })
            },
			// 弹出新增参数编辑框
			onClickAdd() {
                this.formTitle = '添加新公告'
				this.dialogForm = true
                this.noneChanged = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
                this.form.cover = false;
                this.form.serverids = [];                
                this.formServers = [];
			},
            // 弹出修改参数编辑框
            onClickEditNotice(row) {
                this.formTitle = '修改公告'
				this.dialogForm = true
                this.noneChanged = true 
                this.form = JSON.parse(JSON.stringify(row))
                this.form.notice_time = [new Date(row.begin_time), new Date(row.end_time)]
                if (row.maintain_time) {
                    this.form.maintain_time = new Date( row.maintain_time )
                }
                if (row.show_update_time) {
                    this.form.show_update_time = new Date( row.show_update_time )
                }                
                // 加载区服列表
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: this.form.groupid
                }).then((response) => {
                    this.formServers = response.data.list
                })
            },
            // 弹出删除确认框
            onClickDelNotice(row) {
                this.$confirm('是否删除[ '+row.title+' ]公告?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3003, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshNoticeList()
                        }
                    })
                }).catch(() => {})
            },    
            // 表单分组选择变化
            onFormSelectGroup(itemid) {
                this.noneChanged = false
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: itemid,
                }).then((response) => {
                    this.formServers = response.data.list
                })
            },
            onFormSelectServer() {
                this.noneChanged = false
            },
            // 编辑框内容变化事件
            onFormEdited() {
                this.noneChanged = false
            },           
			// 添加在线配置
			onAddNotice() {
				if (!this.form.title) {
                    this.$message.error('公告标题不能为空'); return;
                }
				if (!this.form.content) {
                    this.$message.error('公告内容不能为空'); return;
                }
				if (!this.form.notice_time) {
                    this.$message.error('公告时间范围未设置'); return;
                }            
                if ("local"==this.form.imgtype || "remote"==this.form.imgtype) {
                    if (!this.form.imgvalue) {
                        this.$message.error('选择图片类型后，需指定图片'); return;                        
                    }
                }
                this.dialogForm = false
                let notice = {
                    id: this.form.id,
                    groupid: this.form.groupid,
                    serverids: this.form.serverids,
                    ntype: this.form.ntype,
                    title: this.form.title,
                    img_title: this.form.img_title,
                    img_id: this.form.img_id,
                    cover: this.form.cover?1:0,
                    sortv: this.form.sortv,
                    content: this.form.content,
                    imgtype: this.form.imgtype,
                    imgvalue: this.form.imgvalue,
                    begin_time: tool.dateFormat( this.form.notice_time[0] ),
                    end_time: tool.dateFormat( this.form.notice_time[1] ),
                    maintain_time: this.form.maintain_time ? tool.dateFormat(this.form.maintain_time ) : '',
                    show_update_time: this.form.show_update_time ? tool.dateFormat(this.form.show_update_time ) : '',                    
                }
                console.log(notice)                
                // 请求操作
                this.gameHttp(3002, notice).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshNoticeList()
                        this.$message.success(response.data.errmsg)
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
			},
            // 选择服务器分组
            onSelectGroup(item) {
                this.curGroupLabel = item.label
                this.curGroupId = item.value
                // 刷新选择结果
                this.refreshNoticeList()
            }, 
            // 选择公告类型查看
            onSelectNtype() {
                this.refreshNoticeList()
            },
            // 选择通知的图片
            onFormSelectImg() {
                this.form.imgvalue = '';  
            },
			// 弹出上传文件
			onClickUpload() {
				this.dialogUpload = true
				this.noneUploaded = true
				this.uploadFileList = []
			},
            onUploadSuccess(response, file) {
                console.log("onUploadSuccess=>", response)
                this.noneUploaded = false;
                this.uploadFileList.push(file)
                this.uploadFileName = response;
            },
            onUploadRemove(file) {
                console.log("onUploadRemove=>", file)
                this.checkedPkgName = false;
                this.noneUploaded = true;
                this.uploadFileList = []
            },
			// 确定上传的图片
			onCommitUploadImg() {
                this.form.imgvalue = this.uploadFileName;
                this.dialogUpload = false;
			},
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
</style>
